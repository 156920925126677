<script lang="ts">
	import { Image, StructuredText } from '@datocms/svelte'
	import { isParagraph } from 'datocms-structured-text-utils'
	import { LogoCloud, ParagraphLarge } from '$lib/components'

	export let section: any

	$: ({ hero_text, hero_image, buttons } = section || {})
</script>

<section
	class="homepage-hero relative flex h-full w-full flex-col bg-gray-900 pb-48 pt-24 text-white"
>
	<div class="absolute inset-0 z-0">
		{#if Object.keys(hero_image).length > 0}
			<Image data={hero_image.responsiveImage} pictureClass="object-cover" class="h-full w-full"
			></Image>
		{/if}
		<div
			class="absolute inset-0 z-10 min-h-screen w-full bg-gradient-to-b from-transparent to-black opacity-100 lg:opacity-90"
		/>
	</div>

	<div class="relative flex w-full items-end lg:mt-0">
		<div
			class="3xl:mb-18 mx-8 mb-12 mt-24 text-left sm:mx-12 sm:mt-48 lg:mx-16 xl:mx-auto xl:max-w-6xl 2xl:max-w-7xl"
		>
			<div class="mb-12">
				<div class="relative">
					<StructuredText data={hero_text} components={[[isParagraph, ParagraphLarge]]}
					></StructuredText>

					{#if buttons.length}
						<div
							class="mt-12 flex w-full flex-col-reverse items-center justify-center gap-4 md:flex-row lg:hidden"
						>
							{#each buttons as button}
								{#if button._modelApiKey === 'cta_button'}
									<button
										class="btn cta-button {button.primary
											? 'primary'
											: 'secondary'} w-full max-w-sm"
									>
										{button.label}
									</button>
								{:else if button._modelApiKey === 'link_button'}
									<a
										href={button.url}
										class="btn {button.primary ? 'primary' : 'secondary'} white w-full max-w-sm"
									>
										{button.label}
									</a>
								{/if}
							{/each}
						</div>
					{/if}
				</div>
			</div>
			<slot name="cta-hero-mobile" />
		</div>
	</div>
	<LogoCloud />
</section>

<style lang="postcss">
	.homepage-hero :global(h1) {
		@apply mb-6 text-4xl font-extrabold leading-none text-white sm:text-5xl md:text-6xl;
	}

	.homepage-hero :global(h2) {
		@apply mb-12 text-xl font-bold text-white sm:text-3xl md:text-3xl;
	}

	.homepage-hero :global(h2:first-of-type) {
		@apply mb-6;
	}
</style>

<script lang="ts">
	import { Image, StructuredText } from '@datocms/svelte'
	import { isHeading, isBlock, isParagraph } from 'datocms-structured-text-utils'
	import {
		ComponentHeading,
		CTA,
		extract_headings,
		extract_non_headings,
		ParagraphLarge,
	} from '$lib/components'

	export let section: any

	$: ({ body, layout, background_image } = section || {})

	$: headings = extract_headings(body)
	$: non_headings = extract_non_headings(body)
</script>

<section class="statement {layout}">
	<div class="container px-8 py-16 sm:py-24">
		{#if layout === 'narrow-gray' || layout === 'narrow-white'}
			<StructuredText
				data={body}
				components={[
					[isHeading, ComponentHeading],
					[isBlock, CTA],
					[isParagraph, ParagraphLarge],
				]}
			></StructuredText>
		{:else if layout === 'bg-image'}
			<div class="absolute inset-0 z-0">
				<Image
					data={background_image.responsiveImage}
					pictureClass="object-cover"
					class="h-full w-full"
				></Image>
				<div
					class="absolute inset-0 z-10 min-h-screen w-full bg-gradient-to-b from-transparent to-black opacity-100 lg:opacity-90"
				/>
			</div>

			<div class="relative">
				<StructuredText
					data={body}
					components={[
						[isHeading, ComponentHeading],
						[isBlock, CTA],
						[isParagraph, ParagraphLarge],
					]}
				></StructuredText>
			</div>
		{:else}
			<div class="left-column">
				<StructuredText data={headings} components={[[isHeading, ComponentHeading]]}
				></StructuredText>
			</div>

			<div class="right-column">
				<div class="sm:max-w-screen-md">
					<StructuredText
						data={non_headings}
						components={[
							[isBlock, CTA],
							[isParagraph, ParagraphLarge],
						]}
					/>
					<div class="mt-12 flex justify-center sm:justify-start">
						<div class="flex justify-center">
							<slot name="cta-recruiting-sales" />
						</div>
					</div>
				</div>
			</div>
		{/if}
	</div>
</section>

<style lang="postcss">
	section {
		@apply relative grid w-full place-items-center;
	}

	.statement :global(h2) {
		@apply text-4xl font-extrabold leading-none text-gray-900 sm:text-5xl md:text-6xl;
	}

	.statement :global(h2:last-of-type) {
		@apply mb-8;
	}

	.statement :global(h3) {
		@apply mb-6 text-xl font-extrabold text-gray-500 sm:text-xl;
	}

	.statement :global(h4) {
		@apply mt-12 text-xl font-extrabold text-gray-900 sm:text-2xl;
	}

	.statement :global(button) {
		@apply mt-8;
	}

	.statement.bg-image :global(h2) {
		@apply text-4xl tracking-tight text-white sm:text-5xl lg:text-6xl;
	}

	.statement.bg-image :global(h3) {
		@apply text-xl font-semibold leading-tight tracking-tight text-white sm:text-3xl md:text-3xl 3xl:text-4xl;
	}

	.statement.bg-image :global(p) {
		@apply text-xl font-semibold leading-tight tracking-tight text-white sm:text-3xl md:text-3xl 3xl:text-4xl;
	}

	.statement.bg-image :global(button) {
		@apply mt-12;
	}

	.narrow-gray {
		@apply bg-gray-200;
	}

	.narrow-white {
		@apply bg-white;
	}

	.wide-white {
		@apply bg-gray-50;
	}

	.bg-image {
		@apply relative overflow-hidden text-white;
	}

	.wide-white .container {
		@apply max-w-6xl;
	}

	.bg-image .container,
	.narrow-gray .container,
	.narrow-white .container {
		@apply max-w-4xl;
	}

	.bg-image .container {
		@apply self-end justify-self-start pb-12 pt-48 lg:pl-20;
	}

	.left-column {
		@apply w-full sm:w-3/4;
	}

	.right-column {
		@apply mt-8 flex w-full justify-end sm:mt-12;
	}

	.hiral-background-image-2 {
		background-image: url(/i/splainin-1028772240.jpg);
	}
</style>

<script lang="ts">
	// This is only a wrapper component to apply the correct styles to the paragraph without specifying the text_size prop
	import { Paragraph } from '$lib/components'
	export let node: any

	$: ({ type } = node || {})
</script>

{#if type === 'paragraph'}
	<Paragraph text_size="large"><slot></slot></Paragraph>
{/if}

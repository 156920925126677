import type { Node, Heading, StructuredText } from 'datocms-structured-text-utils'
import { isHeading } from 'datocms-structured-text-utils'

// Define the DAST types
export interface CTA_Block {
	id: string
	_modelApiKey: string
	primary: boolean
	label: string
}

// Function to extract headings
export function extract_headings(data: StructuredText): StructuredText {
	if (!data.value || !data.value.document || !Array.isArray(data.value.document.children)) {
		throw new Error('Invalid data structure')
	}

	const headings = data.value.document.children.filter((child: Node) =>
		isHeading(child),
	) as Heading[]
	return {
		value: {
			schema: data.value.schema,
			document: {
				type: 'root',
				children: headings,
			},
		},
		blocks: data.blocks,
	}
}

// Function to extract non-headings
export function extract_non_headings(data: StructuredText): StructuredText {
	if (!data.value || !data.value.document || !Array.isArray(data.value.document.children)) {
		throw new Error('Invalid data structure')
	}

	const nonHeadings = data.value.document.children.filter((child: Node) => !isHeading(child))
	return {
		value: {
			schema: data.value.schema,
			document: {
				type: 'root',
				children: nonHeadings,
			},
		},
		blocks: data.blocks,
	}
}

<script lang="ts">
	import { StructuredText } from '@datocms/svelte'
	import { isHeading } from 'datocms-structured-text-utils'
	import { ComponentHeading, extract_headings, extract_non_headings } from '$lib/components'

	export let section: any
	$: ({ header, rows, layout } = section || {})
</script>

<section
	class="process sm:py-26 relative grid w-full place-items-center bg-gray-50 px-8 py-16 sm:px-20 md:px-8"
>
	<div class="mb-16 w-full max-w-6xl lg:mb-24">
		<div class="w-full">
			<StructuredText data={header} components={[[isHeading, ComponentHeading]]} />
		</div>
	</div>
	{#each rows as row, i}
		{#if row._modelApiKey === 'process_step'}
			<div
				class="mb-16 grid w-full max-w-6xl place-items-center gap-14 lg:place-items-start {layout ===
				'numbers'
					? 'lg:grid-cols-[auto_1fr_1fr]'
					: 'lg:grid-cols-[2fr_3fr]'}"
			>
				{#if layout === 'numbers'}
					<div class="text-8xl font-black text-orange-600">
						{i + 1}
					</div>
				{/if}

				<div
					class="container text-center text-4xl font-black leading-none tracking-tight text-gray-900 sm:text-4xl lg:inline lg:text-left lg:text-5xl"
				>
					<StructuredText
						data={extract_headings(row.body)}
						components={[[isHeading, ComponentHeading]]}
					/>
					{#if layout === 'bullets'}
						<div class="mt-8 rotate-90 text-orange-600 lg:mt-0 lg:rotate-0">&nbsp;→</div>
					{/if}
				</div>

				<div class="w-full">
					<StructuredText
						data={extract_non_headings(row.body)}
						components={[[isHeading, ComponentHeading]]}
					/>
				</div>
			</div>
		{:else if row._modelApiKey === 'cta_button'}
			<div class="grid w-full place-items-center sm:my-8">
				<button class="cta-button btn {row.primary} w-full max-w-sm">{row.label}</button>
			</div>
		{/if}
	{/each}
</section>

<style lang="postcss">
	.process :global(h3) {
		@apply mb-6 text-center text-xl font-extrabold text-gray-500 sm:text-xl lg:text-left;
	}

	.process :global(h2) {
		@apply text-center text-4xl font-extrabold leading-none text-gray-900 sm:text-5xl md:text-6xl lg:text-left;
	}

	.process :global(p) {
		@apply mt-6 text-lg leading-snug text-gray-700 sm:text-xl sm:leading-normal;
	}

	.process .container :global(*) {
		@apply block lg:inline;
	}

	.process :global(p:first-of-type) {
		@apply mt-0;
	}
</style>

<script lang="ts">
	import { PUBLIC_CANONICAL_DOMAIN } from '$env/static/public'
	import { onMount } from 'svelte'
	import { get_modal_state } from '$lib/components/context'

	import {
		Advantages,
		Header,
		HomeHero,
		HomeMenu,
		StarsTestimonials,
		Statement,
		StatementList,
		Stats,
		Testimonial,
		VideoTestimonial,
	} from '$lib/components'

	let is_modal_open = get_modal_state()

	// Add event listeners to all dynamically rendered CTA buttons
	onMount(() => {
		const cta_buttons = document.querySelectorAll('.cta-button')

		cta_buttons.forEach((button) => {
			button.addEventListener('click', toggle_modal)
		})
	})

	// Helper function to toggle the modal state
	function toggle_modal() {
		$is_modal_open = !$is_modal_open
	}

	export let data

	$: ({ homepage } = data || {})
	$: ({ sections } = homepage || {})
</script>

<svelte:head>
	<title>hiral — eine der innovativsten Recruiting-Agenturen Europas</title>
	<meta
		name="description"
		content="hiral kombiniert Marketing-Methoden aus dem E-Commerce mit der gezielten Direktansprache von Top-Kandidaten."
	/>
	<link rel="canonical" href={PUBLIC_CANONICAL_DOMAIN} />
</svelte:head>

<Header>
	<HomeMenu />
</Header>

<main class="relative z-10 min-h-screen">
	{#each sections as section}
		{#if section._modelApiKey === 'homepage_hero_section'}
			<HomeHero {section} />
		{:else if section._modelApiKey === 'lp_advantages_section'}
			<Advantages {section} />
		{:else if section._modelApiKey === 'lp_star_testimonial_section'}
			<StarsTestimonials {section} />
		{:else if section._modelApiKey === 'stats_section'}
			<Stats {section} />
		{:else if section._modelApiKey === 'statement_section'}
			<Statement {section} />
		{:else if section._modelApiKey === 'process_section'}
			<StatementList {section} />
		{:else if section._modelApiKey === 'testimonial_section'}
			<Testimonial {section} />
		{:else if section._modelApiKey === 'video_testimonial_section'}
			<VideoTestimonial {section} />
		{/if}
	{/each}

	<section class="relative bg-white pb-24">
		<div class="grid justify-center px-8">
			<img
				src="/i/hiral_kununu_siegel.jpg"
				alt="Kununu Top und Open Company"
				class="block h-auto w-full max-w-lg"
			/>
		</div>
	</section>
</main>

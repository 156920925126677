<script lang="ts">
	import { isBlock, type Block } from 'datocms-structured-text-utils'
	// import type { QuoteRecord } from './types'

	export let node: Block
	export let block: any // This is the block data

	$: ({ primary, label } = block || {})
</script>

{#if isBlock(node)}
	<button
		class="cta-button btn {primary ? 'primary' : 'secondary'} w-full min-w-60 max-w-96 sm:w-auto"
		>{label}</button
	>
{/if}
